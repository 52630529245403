import React from "react";
import { useHistory } from "react-router-dom";
import { Title } from "components/UI/Title";
import { Table, Props as TableProps } from "components/UI/Table";
import { ClaimType, PageInfoType } from "graphql/types/types";
import { Pagination, PaginationPayload } from "components/UI/Pagination";
import { Spinner } from "components/UI/Spinner";
import { SOURCE_TYPE } from "common/const/source";
import { CLAIMS_CATEGORY_TYPE } from "common/const/category";
import { CommentClaims } from "../CommentClaims";
import { getValidComments } from "../helpers";
import { ROUTE } from "routes";

import "./styles.scss";

type Props = {
  className?: string;
  claims: ClaimType[];
  activeCategory: number | null;
  meta: PageInfoType | null;
  loading: boolean;
  onChangePagination: (
    categoryId: number,
    pagination: PaginationPayload
  ) => void;
};

export const ClaimsTable: React.FC<Props> = ({
  activeCategory,
  meta,
  loading,
  onChangePagination,
  claims,
}) => {
  const history = useHistory();

  const columns: TableProps<ClaimType>["columns"] = [
    {
      title: "Категория ",
      dataIndex: "category",
      key: "category",
      render: (category) => CLAIMS_CATEGORY_TYPE[category],
    },
    {
      title: "Фамилия",
      dataIndex: "secondName",
      key: "secondName",
      render: (secondName) => (
        <div className="claims-table-column-name" title={secondName}>
          {secondName}
        </div>
      ),
    },
    {
      title: "Имя",
      dataIndex: "firstName",
      key: "firstName",
      render: (firstName) => (
        <div className="claims-table-column-name" title={firstName}>
          {firstName}
        </div>
      ),
    },
    {
      title: "Город",
      dataIndex: "address",
      key: "address",
      render: (address) => (
        <div className="claims-table-column-name" title={address}>
          {address.city}
        </div>
      ),
    },
    {
      title: "Телефон",
      dataIndex: "contacts",
      key: "phone",
      render: (contacts) => (
        <div className="claims-table-column-name" title={contacts.phone}>
          {contacts.phone}
        </div>
      ),
    },
    {
      title: "Желаемая должность",
      dataIndex: "position",
      key: "position",
      width: 180,
      render: (position) => (
        <div className="nowrap" title={position}>
          {position}
        </div>
      ),
    },
    {
      title: "Желаемая зарплата",
      dataIndex: "salary",
      key: "salary",
      render: (salary) => salary ?? "",
    },
    {
      title: "Комментарий",
      dataIndex: "userComment",
      key: "userComment",
      render: (_, claim) => {
        const comments = getValidComments(claim);
        return comments.length ? <CommentClaims comments={comments} /> : null;
      },
    },
    {
      title: "Источник",
      dataIndex: "source",
      key: "source",
      render: (source) => SOURCE_TYPE[source],
    },
  ];

  return (
    <div className="claims-table">
      <Title type="h3">{`Отклики для работы «Заявки»`}</Title>

      {loading ? (
        <Spinner className="claims-table-spinner" loading={loading} />
      ) : (
        <Table
          tableLayout="auto"
          draggableScroll
          dataSource={claims}
          columns={columns}
          onRow={(claim) => ({
            onClick: () => {
              history.push(`${ROUTE.CLAIM}/${claim.id}`);
            },
          })}
        />
      )}
      <Pagination
        className="candidates-container-pagination"
        total={meta?.total}
        limit={meta?.limit}
        offset={meta?.offset}
        onChange={(pagination) =>
          onChangePagination(Number(activeCategory), pagination)
        }
      />
    </div>
  );
};
