import React from "react";
import { useHistory } from "react-router-dom";

import {
  CandidateType,
  ReplyNewClientType,
  ReplyNewStatuses,
  UserClientType,
  VacancyType,
} from "graphql/types/types";
import { Table, TableProps } from "components/UI/Table";
import { getFullName } from "common/helpers/string";
import { REPLY_NEW_STATUS_TYPE } from "common/const/status";
import { getMainPhone } from "common/helpers/phone";
import { ROUTE } from "routes";

type Props = {
  replies: ReplyNewClientType[];
  className?: string;
};

export const CandidateRepliesTable: React.FC<Props> = ({ replies }) => {
  const history = useHistory();

  const columns: TableProps<ReplyNewClientType>["columns"] = [
    {
      title: "ID Вакансии",
      dataIndex: "vacancy",
      key: "vacancy",
      render: (vacancy: VacancyType) => vacancy.id,
    },
    {
      title: "Дата перевода",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "ФИО МП",
      dataIndex: "manager",
      key: "manager",
      render: (manager: UserClientType) => getFullName(manager),
    },
    {
      title: "ФИО МА",
      dataIndex: "manager",
      key: "manager",
      render: (manager: UserClientType) => getFullName(manager),
    },
    {
      title: "Регион вакансии",
      dataIndex: "vacancy",
      key: "vacancy",
      render: (vacancy: VacancyType) => vacancy.region,
    },
    {
      title: "ФИО Кандидата",
      dataIndex: "candidate",
      key: "candidate",
      render: (candidate: CandidateType) => getFullName(candidate),
    },
    {
      title: "Статус кандидата",
      dataIndex: "status",
      key: "status",
      render: (status: ReplyNewStatuses) => REPLY_NEW_STATUS_TYPE[status],
    },
    {
      title: "Телефон кандидата",
      dataIndex: "candidate",
      key: "candidate",
      render: (candidate: CandidateType) => getMainPhone(candidate.contacts),
    },
  ];

  const handleRowClick = (replyId: string) => {
    history.push({
      pathname: `${ROUTE.CANDIDATE_REPLY}/${replyId}`,
    });
  };

  return (
    <Table
      tableLayout="auto"
      dataSource={replies}
      columns={columns}
      onRow={(reply) => ({
        onClick: () => handleRowClick(reply.id),
      })}
    />
  );
};
