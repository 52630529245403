import React, { useCallback } from "react";
import { Moment } from "moment";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "services/store/store";
import { selectFilteredCandidateStatuses } from "services/store/modules/candidate/selectors";
import {
  CandidateStatus,
  CandidateType,
  RefusalReasonType,
  RefusalType,
  ReplyNewViewStatuses,
  VacancyType,
} from "graphql/types/types";
import { removeCandidate } from "services/store/modules/candidates";
import { updateCandidateInReply } from "services/store/modules/repliesList";
import { useCandidateStatus } from "common/hooks/candidate/useCandidateStatus";
import { useReplyStatus } from "common/hooks/reply/useReplyStatus";
import { parseQuery } from "common/utils/query";
import { Cascader, Option } from "components/UI/Cascader";
import { selectRefusalReasonsByType } from "services/store/modules/directory";
import FormDatePicker from "components/UI/Form/FormDatePicker";
import { getSelectOptions } from "components/UI/Select/helpers";
import { getCandidateStatusType } from "components/CandidatesTable/helpers";
import { REFUSAL_REASONS_REQUIRING_DATE_KEYS } from "common/const/candidate";
import toaster from "components/UI/Notifications/Notification";

import "./styles.scss";

interface Props {
  candidate: CandidateType;
  vacancyId?: VacancyType["id"];
  disable?: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}
export const FieldStatus: React.FC<Props> = ({
  candidate,
  vacancyId,
  setIsModalOpen,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { fromReply } = parseQuery(location.search);
  const isFromReply = Boolean(fromReply);
  const { updateCandidateStatus } = useCandidateStatus({ isShowToaster: true });
  const { updateReplyStatus } = useReplyStatus({ isShowToaster: true });

  const refusalReasons = useAppSelector((state) =>
    selectRefusalReasonsByType(state, RefusalType.CandidateRefusal)
  );
  const deniedReasons = useAppSelector((state) =>
    selectRefusalReasonsByType(state, RefusalType.CandidateDenied)
  );
  const notThroughReasons = useAppSelector((state) =>
    selectRefusalReasonsByType(state, RefusalType.CandidateNotThrough)
  );

  const filteredCandidateStatuses = useAppSelector(
    selectFilteredCandidateStatuses(
      isFromReply ? CandidateStatus.Candidate : undefined
    )
  );

  const getChildrenOptions = (status: CandidateStatus) => {
    const type = getCandidateStatusType(status);

    if (!type) return [];

    const renderByType = {
      reason: refusalReasons.map((reason) => {
        return {
          value: reason.id,
          label: reason.name,
          children: REFUSAL_REASONS_REQUIRING_DATE_KEYS.includes(reason.key)
            ? [
                {
                  value: "date",
                  label: (
                    <div
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <FormDatePicker
                        label="Дата и время"
                        style={{ width: "200px" }}
                        format="DD.MM.YYYY HH:mm"
                        placeholder="DD.MM.YYYY HH:mm"
                        size="small"
                        showTime
                        onClick={(e) => e.stopPropagation()}
                        onOk={(date) => {
                          updateStatus(
                            CandidateStatus.RefusalCandidate,
                            reason.id,
                            date
                          );
                        }}
                      />
                    </div>
                  ),
                },
              ]
            : undefined,
        };
      }),
      denied: getSelectOptions(deniedReasons),
      notThrough: getSelectOptions(notThroughReasons),
      date: [
        {
          value: "date",
          label: (
            <div
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
            >
              <FormDatePicker
                label="Дата и время"
                style={{ width: "200px" }}
                format="DD.MM.YYYY HH:mm"
                placeholder="DD.MM.YYYY HH:mm"
                size="small"
                showTime
                onClick={(e) => e.stopPropagation()}
                onOk={(date) => {
                  updateStatus(CandidateStatus.Callback, undefined, date);
                }}
              />
            </div>
          ),
        },
      ],
    };

    return renderByType[type] || [];
  };

  const updateStatus = useCallback(
    async (
      status: CandidateStatus,
      reason?: RefusalReasonType["id"] | null,
      date?: Moment | null
    ) => {
      await updateCandidateStatus({
        id: candidate.id,
        status,
        callTime: date,
        refusalId: reason ?? undefined,
        vacancyId,
      }).then((result) => {
        dispatch(removeCandidate({ candidateId: candidate.id }));

        if (result?.changeCandidateStatus) {
          dispatch(updateCandidateInReply(result.changeCandidateStatus));
        }
        if (status === CandidateStatus.Candidate) {
          setIsModalOpen(true);
        }
      });
    },
    [candidate.id, dispatch, setIsModalOpen, updateCandidateStatus, vacancyId]
  );

  const updateStatusFromReply = useCallback(
    async (status: ReplyNewViewStatuses) => {
      try {
        await updateReplyStatus({
          replyId: fromReply ?? "",
          status: status,
          vacancyId,
        });
        setIsModalOpen(true);
      } catch (error) {
        toaster.error({ title: error });
      }
    },
    [fromReply, updateReplyStatus, vacancyId]
  );

  const handleChange = (value: (string | number)[]) => {
    const [rawStatus, secondLevel] = value;
    const selectedStatus = rawStatus as CandidateStatus;

    switch (value.length) {
      case 1:
        return isFromReply
          ? updateStatusFromReply(ReplyNewViewStatuses.Candidate)
          : updateStatus(selectedStatus);

      case 2:
        return secondLevel === "data"
          ? undefined
          : updateStatus(
              selectedStatus,
              secondLevel as RefusalReasonType["id"]
            );
    }
  };

  const options: Option[] = filteredCandidateStatuses.map(({ key, name }) => ({
    value: key,
    label: name,
    children: getChildrenOptions(key as CandidateStatus),
  }));

  return (
    <div className="field-status" onClick={(e) => e.stopPropagation()}>
      <Cascader
        size="small"
        options={options}
        onChange={handleChange}
        className="field-status-cascader"
        placeholder="Выберите статус"
        dropdownClassName="field-status-cascader-dropdown"
      />
    </div>
  );
};
