import React from "react";

import { useAppSelector } from "services/store/store";

import { selectRepliesNewListData } from "services/store/modules/repliesNew/selectors";
import { CandidateRepliesTable } from "components/redezign/CandidateReplies/components/CandidateRepliesTable";
import { ColumnLayout } from "layouts/ColumnLayout";
import { Filters } from "./components/Filters";
import { selectCandidateStatuses } from "services/store/modules/candidate/selectors";
import { selectAreas } from "services/store/modules/directory";

type Props = {
  className?: string;
};

export const CandidateReplies: React.FC<Props> = () => {
  const replies = useAppSelector(selectRepliesNewListData);
  const candidateStatuses = useAppSelector(selectCandidateStatuses);
  const areasOptions = useAppSelector(selectAreas);

  return (
    <ColumnLayout
      header={
        <Filters
          candidateStatuses={candidateStatuses}
          areasOptions={areasOptions}
        />
      }
    >
      {replies && <CandidateRepliesTable replies={replies} />}
    </ColumnLayout>
  );
};
