import React from "react";

import { ColumnLayout } from "layouts/ColumnLayout";
import { VacancyInfo } from "components/VacancyInfo";
import { ReplyNewClientType } from "graphql/types/types";
import { IUser } from "services/store/modules/user/types";
import { CandidatesTable } from "components/CandidatesTable";

import "./styles.scss";
import { Title } from "components/UI/Title";

type Props = {
  className?: string;
  reply: ReplyNewClientType;
  authData: IUser;
};

export const CandidateReply: React.FC<Props> = ({ reply, authData }) => {
  return (
    <ColumnLayout className="candidate-reply">
      <VacancyInfo
        className="candidate-reply-vacancy"
        hiddenActions={true}
        vacancy={reply.vacancy}
        userData={authData}
      />

      <div className="candidate-reply-wrapper">
        <Title type="h3">Анкета кандидата</Title>

        <CandidatesTable mode="reply" candidatesList={[reply.candidate]} />
      </div>
    </ColumnLayout>
  );
};
