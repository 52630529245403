import { lazy } from "react";
import { Redirect, RouteProps } from "react-router-dom";
import Auth from "./views/Auth/Auth";
import Authorizer from "./views/Auth/Authorizer";
import { CandidatesPage } from "views/redezign/CandidatesPage";
import { VacanciesPage } from "views/redezign/VacanciesPage";
import { VacancyPage } from "views/redezign/VacancyPage";

import { CandidatePage } from "views/redezign/CandidatePage";
import { RecommendedPage } from "./views/redezign/RecommendedPage";
import { RepliesPage } from "./views/redezign/RepliesPage";
import { ReportsPage } from "views/redezign/ReportsPage";
import { AdminSettingPage } from "./views/redezign/AdminSettingPage";
import { ReportPage } from "views/redezign/ReportPage";
import { PositionsMapPage } from "views/SystemSettings/PositionsMapPage";
import { ReplyPage } from "views/redezign/ReplyPage";
import { ClaimPage } from "views/redezign/ClaimPage";
import { PlansPage } from "./views/redezign/PlansPage";
import { PlanPage } from "./views/redezign/PlanPage";
import { RepliesNewPage } from "./views/redezign/RepliesNewPage";
import { ReplyNewPage } from "./views/redezign/ReplyNewPage";
import { CandidateRepliesPage } from "./views/redezign/CandidateRepliesPage";
import { CandidateReplyPage } from "views/redezign/CandidateReplyPage";

export type RouteItem = RouteProps & {
  privateRoute?: boolean;
  breadcrumbs?: Array<{ text: string; url?: string }>;
};

const Invitation = lazy(() => import("./views/Auth/Invitation"));
const VacancyView = lazy(
  () => import("./views/Vacancies/VacancyView/VacancyView")
);
const VacancyList = lazy(
  () => import("./views/Vacancies/VacancyList/VacancyList")
);
const Page404 = lazy(() => import("./views/Page404"));
const Profile = lazy(() => import("./views/Profile/Profile"));
const CompanyProfile = lazy(
  () => import("./views/CompanyProfile/CompanyProfile")
);
const ReplyList = lazy(() => import("./views/Replies/ReplyList"));
const ClaimList = lazy(() => import("./views/Claims/ClaimList"));
const Departments = lazy(() => import("./views/Departments/Departments"));
const UserView = lazy(() => import("./views/Users/UserView/UserView"));
const Control = lazy(() => import("./views/Control/Control"));
const SystemSettingList = lazy(
  () => import("./views/SystemSettings/SystemSettingList")
);
const DictionaryView = lazy(
  () => import("./views/SystemSettings/DictionaryView")
);

export const ROUTE = {
  VACANCY: "/vacancy-view",
  VACANCIES: "/vacancies-view",
  CANDIDATE: "/candidate",
  CANDIDATES: "/candidates",
  RECOMMENDED: "/recommended",
  REPLIES: "/replies-view",
  REPORTS: "/reports",
  REPORT: "/report",
  REPLY: "/reply-view",
  CLAIM: "/claim",
  PLANS: "/plans",
  PLAN: "/plan",
  REPLIES_NEW: "/new-replies",
  REPLY_NEW: "/new-reply",
  CANDIDATE_REPLIES: "/work-candidate-replies",
  CANDIDATE_REPLY: "/work-candidate-reply",
} as const;

export type Route = (typeof ROUTE)[keyof typeof ROUTE];

export const routes: RouteItem[] = [
  {
    path: "/login",
    exact: true,
    component: () => <Auth />,
  },
  {
    path: "/authorizer",
    exact: true,
    component: () => <Authorizer />,
  },
  {
    path: "/",
    exact: true,
    privateRoute: true,
    component: () => <Redirect to={"/vacancies"} />,
  },
  {
    path: "/users",
    exact: true,
    privateRoute: true,
    component: () => <Invitation />,
    breadcrumbs: [{ text: "Список сотрудников", url: "/users" }],
  },
  {
    path: ROUTE.VACANCIES,
    exact: true,
    privateRoute: true,
    component: () => <VacanciesPage />,
  },
  {
    path: "/vacancies/:id?",
    exact: true,
    privateRoute: true,
    component: () => <VacancyList />,
  },

  {
    path: "/control",
    exact: true,
    privateRoute: true,
    component: () => <Control />,
    breadcrumbs: [{ text: "Контроль" }],
  },
  {
    path: "/admin-setting",
    exact: true,
    privateRoute: true,
    component: () => <AdminSettingPage />,
  },
  {
    path: "/vacancies/create",
    exact: true,
    privateRoute: true,
    component: () => <Redirect to="/vacancies/new" />,
  },
  {
    path: "/vacancy/new",
    exact: true,
    privateRoute: true,
    component: () => <VacancyView />,
    breadcrumbs: [
      { text: "Список вакансий", url: "/vacancies" },
      { text: "Создание вакансии" },
    ],
  },
  {
    path: "/vacancy/:id",
    privateRoute: true,
    exact: true,
    component: () => <VacancyView />,
    breadcrumbs: [
      { text: "Список вакансий", url: ROUTE.VACANCIES },
      { text: "Редактирование вакансии" },
    ],
  },
  {
    path: `${ROUTE.VACANCY}/:id`, // TODO оставить /vacancy/:id после перехода на новый дизайн
    privateRoute: true,
    exact: true,
    component: () => <VacancyPage />,
  },
  {
    path: "/profile/:tab?",
    exact: true,
    privateRoute: true,
    component: () => <Profile />,
    breadcrumbs: [{ text: "Профиль" }],
  },
  {
    path: "/company/:tab?",
    exact: true,
    privateRoute: true,
    component: () => <CompanyProfile />,
    breadcrumbs: [{ text: "Компания" }],
  },
  //TODO: удалить, когда будет готова страница Кандидаты
  {
    path: "/replies",
    exact: true,
    privateRoute: true,
    component: () => <ReplyList />,
    breadcrumbs: [{ text: "Отклики" }],
  },
  {
    path: ROUTE.REPLIES,
    exact: true,
    privateRoute: true,
    component: () => <RepliesPage />,
  },
  {
    path: ROUTE.REPLIES_NEW,
    exact: true,
    privateRoute: true,
    component: () => <RepliesNewPage />,
  },
  {
    path: ROUTE.CANDIDATE_REPLIES,
    exact: true,
    privateRoute: true,
    component: () => <CandidateRepliesPage />,
  },
  {
    path: `${ROUTE.CANDIDATE_REPLY}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <CandidateReplyPage />,
  },
  {
    path: `${ROUTE.REPLY}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <ReplyPage />,
  },
  {
    path: `${ROUTE.REPLY_NEW}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <ReplyNewPage />,
  },
  {
    path: `${ROUTE.CLAIM}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <ClaimPage />,
  },
  {
    path: ROUTE.CANDIDATES,
    exact: true,
    privateRoute: true,
    component: () => <CandidatesPage />,
  },
  {
    path: `${ROUTE.CANDIDATE}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <CandidatePage />,
  },
  {
    path: `${ROUTE.RECOMMENDED}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <RecommendedPage />,
  },
  {
    path: ROUTE.REPORTS,
    exact: true,
    privateRoute: true,
    component: () => <ReportsPage />,
  },
  {
    path: `${ROUTE.REPORT}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <ReportPage />,
  },
  {
    path: ROUTE.PLANS,
    exact: true,
    privateRoute: true,
    component: () => <PlansPage />,
  },
  {
    path: `${ROUTE.PLAN}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <PlanPage />,
  },
  {
    path: "/claims",
    exact: true,
    privateRoute: true,
    // @ts-ignore
    component: () => <ClaimList />,
    breadcrumbs: [{ text: "Заявки" }],
  },
  {
    path: "/departments",
    exact: true,
    privateRoute: true,
    component: () => <Departments />,
    breadcrumbs: [{ text: "Департаменты" }],
  },
  {
    path: "/user/:id",
    exact: true,
    privateRoute: true,
    component: () => <UserView />,
    breadcrumbs: [
      { text: "Компания", url: "/company" },
      { text: "Сотрудники", url: "/company/users" },
      { text: "Редактирование" },
    ],
  },
  {
    path: "/settings",
    exact: true,
    privateRoute: true,
    component: () => <SystemSettingList />,
    breadcrumbs: [{ text: "Настройки системы" }],
  },
  {
    path: "/settings/dictionary/:type?",
    exact: true,
    privateRoute: true,
    component: () => <DictionaryView />,
    breadcrumbs: [
      { text: "Настройки системы", url: "/settings" },
      { text: "Редактирование словаря" },
    ],
  },
  {
    path: "/settings/positions-map",
    exact: true,
    privateRoute: true,
    component: () => <PositionsMapPage />,
    breadcrumbs: [
      { text: "Настройки системы", url: "/settings" },
      { text: "Справочник должностей" },
    ],
  },
  {
    path: "/invitation",
    exact: true,
    privateRoute: true,
    component: () => <Invitation />,
    breadcrumbs: [
      { text: "Сотрудники", url: "/company/users" },
      { text: "Добавление сотрудника" },
    ],
  },
  { path: "*", component: () => <Page404 /> },
];
