import React from "react";
import RowLayout from "layouts/RowLayout";
import { VacancyInfo } from "components/VacancyInfo";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { selectNewReplyStatuses } from "services/store/modules/directory";
import { selectReplyLoading } from "services/store/modules/repliesList/selectors";
import {
  VacancyType,
  ReplyNewClientType,
  ReplyNewStatuses,
} from "graphql/types/types";
import { authInfo } from "services/store/modules/auth";
import { hireNewReply } from "services/store/modules/repliesNew/actions";
import toaster from "components/UI/Notifications/Notification";
import { setInProgressBy } from "services/store/modules/repliesNew";
import { ReplyActionPanel } from "../ReplyActionPanel";
import { setCandidate } from "services/store/modules/candidate";

interface Props {
  vacancy: VacancyType;
  reply: ReplyNewClientType;
  className?: string;
}

export const ReplyInfo: React.FC<Props> = ({ vacancy, reply, className }) => {
  const dispatch = useAppDispatch();
  const replyStatusDictionary = useAppSelector(selectNewReplyStatuses);
  const authData = useAppSelector(authInfo);
  const replyLoading = useAppSelector(selectReplyLoading);

  const handleReplyVisibleChange = async () => {
    try {
      const res = await dispatch(hireNewReply({ replyId: reply.id })).unwrap();

      res.hireNewReply.inProgressBy &&
        dispatch(
          setInProgressBy({
            inProgressBy: res.hireNewReply.inProgressBy,
            status: res.hireNewReply.status,
          })
        );
      dispatch(setCandidate(reply.candidate));

      toaster.success({ title: "Отклик в работе" });
    } catch (error) {
      toaster.error({ title: error });
    }
  };

  return (
    <RowLayout
      className={className}
      leftSpan={19}
      leftComponent={
        authData && (
          <VacancyInfo
            hiddenActions={true}
            vacancy={vacancy}
            mapLoading={replyLoading}
            userData={authData}
          />
        )
      }
      centerSpan={5}
      centerComponent={
        <ReplyActionPanel
          replyId={reply.id}
          vacancyId={vacancy.id}
          statuses={replyStatusDictionary}
          status={reply.status}
          isDisabled={
            reply?.inProgressBy === authData?.id ||
            reply.status === ReplyNewStatuses.InWork
          }
          onVisibilityChange={handleReplyVisibleChange}
        />
      }
    />
  );
};
