import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "services/store/store";
import { RouteParams } from "common/commonTypes/types";
import { ContentLayout } from "layouts/ContentLayout";

import { getReplyNew } from "services/store/modules/repliesNew/actions";
import { NotificationStatus } from "components/UI/Notifications/Notification";
import {
  selectReplyNew,
  selectReplyNewLoading,
} from "services/store/modules/repliesNew/selectors";
import { fetchCandidateDictionaries } from "services/store/modules/candidate/actions";
import { fetchVacanciesDictionaries } from "services/store/modules/vacanciesList/actions";
import { getCompanySettings } from "services/store/modules/company";
import { useChangeTitle } from "common/hooks/header/useChangeTitle";
import { ReplyNew } from "components/redezign/ReplyNew";

export const ReplyNewPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id: replyId } = useParams<RouteParams>();
  const reply = useAppSelector(selectReplyNew);
  const replyLoading = useAppSelector(selectReplyNewLoading);

  const title = reply?.vacancy
    ? `Отклик на вакансию ${reply.vacancy.name}`
    : null;

  useChangeTitle({ title });
  useEffect(() => {
    Promise.all([
      dispatch(fetchCandidateDictionaries()),
      dispatch(fetchVacanciesDictionaries()),
      dispatch(getCompanySettings()),
      dispatch(getReplyNew({ id: replyId })),
    ]);
  }, []);

  return (
    <ContentLayout
      centralColumn={{
        content: (
          <>
            {reply && reply.vacancy && !replyLoading ? (
              <ReplyNew vacancy={reply.vacancy} reply={reply} />
            ) : (
              <NotificationStatus
                loading={replyLoading}
                error={null}
                notFoundText="Ошибка загрузки отклика"
              />
            )}
          </>
        ),
      }}
    />
  );
};
