import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { MenuProps } from "antd";
import cn from "classnames/dedupe";

import { useAppDispatch, useAppSelector } from "services/store/store";
import { Props as TableProps, Table } from "components/UI/Table";
import {
  PageInfoType,
  ReplyNewClientType,
  ReplyStatus,
  ReplyType,
  UserClientType,
} from "graphql/types/types";
import { Pagination, PaginationPayload } from "components/UI/Pagination";
import { ROUTE } from "routes";
import { Ellipsis } from "components/UI/icons";
import { REPLY_NEW_STATUS_TYPE } from "common/const/status";
import { COLOR_BY_REPLY_STATUS } from "common/const/colors";
import { Dropdown } from "components/UI/Dropdown";
import { getIsLink } from "common/utils/routes";
import { Spinner } from "components/UI/Spinner";
import { REPLY_SOURCE_TYPE } from "common/const/source";
import { getTableRowDisabled } from "common/helpers/table";
import { selectDisabledRepliesList } from "services/store/modules/repliesList/selectors";
import { department } from "services/store/modules/directory";
import Checkbox from "components/UI/Checkbox";
import { Button } from "components/UI/Button";
import { ReplyTransferListModal } from "../ReplyTransferListModal";
import { hasModerationAccess } from "common/helpers/role";
import { transferNewReplyList } from "services/store/modules/repliesNew/actions";
import toaster from "components/UI/Notifications/Notification";
import { IUser } from "services/store/modules/user/types";

import "./styles.scss";

type Props = {
  className?: string;
  replies: ReplyNewClientType[];
  meta: PageInfoType;
  loading: boolean;
  userData: IUser;
  onChangePagination: (pagination: PaginationPayload) => void;
};

export const RepliesNewTable: React.FC<Props> = ({
  replies,
  meta,
  loading,
  onChangePagination,
  userData,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const disabledRepliesList = useAppSelector(selectDisabledRepliesList);
  const departmentList = useAppSelector(department);

  const [selectedReplyIds, setSelectedReplyIds] = useState<string[]>([]);
  const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);

  const handleSelectRow = (id: string, checked: boolean) => {
    setSelectedReplyIds((prev) =>
      checked ? [...prev, id] : prev.filter((key) => key !== id)
    );
  };

  const getLinkItems = (link: string): MenuProps["items"] => [
    {
      label: <Link to={link}>Просмотр</Link>,
      key: link,
    },
  ];

  const handleTransferReplies = async (newUserId: UserClientType["id"]) => {
    try {
      await dispatch(
        transferNewReplyList({
          ids: selectedReplyIds,
          newUserId,
        })
      ).unwrap();
      toaster.success({ title: "Отклики успешно переданы" });
      setIsTransferModalVisible(false);
      setSelectedReplyIds([]);
    } catch (error) {
      toaster.error({ title: error });
    }
  };

  const userHasAccess = hasModerationAccess(userData.role);

  const selectColumn = {
    title: (
      <Checkbox
        type="single"
        checked={
          selectedReplyIds.length === replies.length && replies.length > 0
        }
        onSingleChange={(e) => {
          const { checked } = e.target;
          if (checked) {
            const allIds = replies.map((r) => r.id);
            setSelectedReplyIds(allIds);
          } else {
            setSelectedReplyIds([]);
          }
        }}
      />
    ),
    dataIndex: "select",
    key: "select",
    width: 50,
    render: (_, record) => (
      <div
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <Checkbox
          type="single"
          checked={selectedReplyIds.includes(record.id)}
          onSingleChange={(e) => {
            handleSelectRow(record.id, e.target.checked);
          }}
        />
      </div>
    ),
  };

  const columns: TableProps<ReplyNewClientType>["columns"] = [
    ...(userHasAccess ? [selectColumn] : []),
    {
      title: "Торговая точка",
      dataIndex: "vacancy",
      key: "vacancy",
      render: (vacancy) => {
        const { project = "" } = vacancy?.callCenterInfo || {};
        return (
          <div
            className={cn("nowrap", "replies-table-column-info")}
            title={project}
          >
            {project}
          </div>
        );
      },
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      width: 80,
      render: (_, record) => (
        <div
          className={cn("nowrap", "replies-table-column-address")}
          title={record.vacancy.address.value ?? ""}
        >
          {record.vacancy.address.value ?? ""}
        </div>
      ),
    },
    {
      title: "Фамилия",
      dataIndex: "lastName",
      key: "lastName",
      render: (lastName) => (
        <div className="replies-table-column-name" title={lastName}>
          {lastName}
        </div>
      ),
    },
    {
      title: "Имя",
      dataIndex: "firstName",
      key: "firstName",
      render: (firstName) => (
        <div className="replies-table-column-name" title={firstName}>
          {firstName}
        </div>
      ),
    },
    {
      title: "Отчество",
      dataIndex: "patronymic",
      key: "patronymic",
      render: (patronymic) => (
        <div className="replies-table-column-name" title={patronymic}>
          {patronymic}
        </div>
      ),
    },
    {
      title: "Вакансия",
      dataIndex: "vacancy",
      key: "vacancy",
      width: 180,
      render: (vacancy) => (
        <div className="nowrap" title={vacancy.name}>
          {vacancy.name}
        </div>
      ),
    },
    {
      title: "Менеджер",
      dataIndex: "manager",
      key: "manager",
      width: 180,
      render: (manager) => (
        <div
          className="nowrap"
          title={`${manager.firstName} ${manager.secondName}`}
        >
          {`${manager.firstName} ${manager.secondName}`}
        </div>
      ),
    },
    {
      title: "Департамент",
      dataIndex: "vacancy",
      key: "vacancy",
      render: (vacancy) => (
        <div className="nowrap">
          {departmentList.find((dep) => dep.id === Number(vacancy.departmentId))
            ?.name ?? ""}
        </div>
      ),
    },
    {
      title: "Источник",
      dataIndex: "source",
      key: "source",
      render: (source) => REPLY_SOURCE_TYPE[source],
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status: ReplyStatus, { isThirtyMinutes, isExpired }) => (
        <div style={{ position: "relative" }}>
          {status === ReplyStatus.New && (isThirtyMinutes || isExpired) && (
            <div className="replies-table-column-status">
              {isExpired ? "Просрочен" : "30 мин"}
            </div>
          )}
          <div
            className="nowrap"
            title={REPLY_NEW_STATUS_TYPE[status]}
            style={{ color: COLOR_BY_REPLY_STATUS[status] }}
          >
            {REPLY_NEW_STATUS_TYPE[status]}
          </div>
        </div>
      ),
    },
    {
      title: "Резюме",
      dataIndex: "candidate",
      key: "candidate",
      width: 75,
      render: (candidate: ReplyType["candidate"]) => {
        if (!candidate?.resumeUrl) {
          return null;
        }

        return getIsLink(candidate.resumeUrl) ? (
          <Dropdown
            trigger={["click"]}
            items={getLinkItems(candidate.resumeUrl)}
          >
            <span
              className="replies-table-link-dropdown-trigger"
              onClick={(e) => e.stopPropagation()}
            >
              <Ellipsis />
            </span>
          </Dropdown>
        ) : null;
      },
    },
  ];

  return (
    <div className="replies-new-table">
      {userHasAccess && (
        <Button
          className="replies-new-table-transfer-btn"
          onClick={() => setIsTransferModalVisible(true)}
          disabled={!selectedReplyIds.length}
        >
          Передать отклики
        </Button>
      )}
      {loading ? (
        <Spinner className="replies-new-table-spinner" loading={loading} />
      ) : (
        <Table
          draggableScroll
          tableLayout="auto"
          dataSource={replies}
          columns={columns}
          onRow={(reply) => ({
            onClick: () => {
              history.push(`${ROUTE.REPLY_NEW}/${reply.id}`);
            },
            className: cn({
              "replies-table-row--disabled": getTableRowDisabled({
                id: reply.id,
                disabledList: disabledRepliesList,
                ownerId: userData.id,
              }),
            }),
          })}
        />
      )}
      <Pagination
        className="candidates-container-pagination"
        total={meta.total}
        limit={meta.limit}
        offset={meta.offset}
        onChange={(pagination) => onChangePagination(pagination)}
      />
      <ReplyTransferListModal
        onClose={() => setIsTransferModalVisible(false)}
        visible={isTransferModalVisible}
        onSubmit={handleTransferReplies}
      />
    </div>
  );
};
