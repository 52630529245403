import {
  CandidateType,
  ManagerType,
  OwnerType,
  UserClientType,
  UserType,
} from "graphql/types/types";

export const getTrueName = (isTrue: boolean) => (isTrue ? "Да" : "Нет");

type GetFullNameProps =
  | ManagerType
  | OwnerType
  | UserType
  | CandidateType
  | UserClientType;

export const getFullName = (props?: GetFullNameProps) => {
  if (props) {
    return `${props.firstName || ""} ${props.secondName || ""}`;
  }
  return "";
};
