import React, { useEffect } from "react";

import { ContentLayout } from "layouts/ContentLayout";
import { CandidateReplies } from "components/redezign/CandidateReplies";
import { useAppDispatch } from "services/store/store";
import { fetchVacanciesDictionaries } from "services/store/modules/vacanciesList/actions";
import { getReplyNewList } from "services/store/modules/repliesNew/actions";
import { PAGINATION } from "common/const/pagination";
import { fetchCandidateDictionaries } from "services/store/modules/candidate/actions";

export const CandidateRepliesPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([
      dispatch(fetchVacanciesDictionaries()),
      dispatch(fetchCandidateDictionaries()),
      dispatch(getReplyNewList({ pagination: PAGINATION.REPLIES })),
    ]);
  }, [dispatch]);

  return <ContentLayout centralColumn={{ content: <CandidateReplies /> }} />;
};
