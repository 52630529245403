import React, { useMemo, useEffect, useState, useCallback } from "react";
import { useForm, Controller, SubmitHandler, useWatch } from "react-hook-form";
import { DaDataAddressSuggestion } from "react-dadata";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router";

import { VacancyStatus, VacancyType } from "graphql/types/types";
import { Notification } from "components/UI/Notifications/Notification";
import { Title } from "components/UI/Title";
import FormText from "components/UI/Form/FormText";
import { FormInput } from "components/UI/Form/FormInput";
import { FormSelect } from "components/UI/Form/FormSelect";
import { Button } from "components/UI/Button";
import { Editor } from "components/UI/Editor";
import { VACANCY_CHARACTER_LIMITS } from "common/const/characterLimits";
import RowLayout from "layouts/RowLayout";
import Input from "components/UI/Input";
import { FormSelectDadata } from "components/UI/Form/FormSelectDadata";
import {
  publishVacancyOnAvito,
  updateVacancyOnAvito,
} from "services/store/modules/vacancy/actions";
import { IUser } from "services/store/modules/user/types";
import {
  selectEmploymentAvito,
  selectRegistrationMethodAvito,
  selectBonusesAvito,
  selectExperienceAvito,
  selectProfessionsAvito,
  selectScheduleAvito,
  selectWorkFormatAvito,
} from "services/store/modules/directory";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { filterOption, getSelectOptions } from "components/UI/Select/helpers";
import { FormCheckbox } from "components/UI/Form/FormCheckbox";
import { Platform } from "common/const/platform";
import { maskPhone } from "common/utils";
import { publicationMessageByState } from "common/const/avito";

import { schema } from "./validation";
import {
  getButtontext,
  getInitialState,
  getPayload,
  isInProgressStatus,
  isPublicationStatus,
} from "./helpers";
import { PublicationStatusModal } from "../PublicationStatusModal";
import { EMPTY_PUBLICATION, getContacts } from "../helpers";

import "./styles.scss";

interface Props {
  vacancy: VacancyType;
  managerList: IUser[];
  changeTab: (key: Platform) => void;
}

export type AvitoState = {
  title: string;
  description: string;
  shortDescription: string;
  terms: string;
  claims: string;
  platformTags: string;
  employment: string;
  experience: string;
  salaryFrom: number;
  salaryTo: number;
  managerId: number;
  address: DaDataAddressSuggestion;
  schedule: string;
  profession: string;
  registrationMethod: string[];
  bonuses: string[];
  workFormat: string[];
};

export const AvitoForm: React.FC<Props> = ({ vacancy, managerList }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const employmentAvitoOptions = useAppSelector(selectEmploymentAvito);
  const experienceAvitoOptions = useAppSelector(selectExperienceAvito);
  const professionsOptions = useAppSelector(selectProfessionsAvito);
  const scheduleAvitoOptions = useAppSelector(selectScheduleAvito);
  const registrationMethodAvitoOptions = useAppSelector(
    selectRegistrationMethodAvito
  );
  const bonusesAvitoOptions = useAppSelector(selectBonusesAvito);
  const workFormatAvitoOptions = useAppSelector(selectWorkFormatAvito);

  const [loading, setLoading] = useState(false);
  const [publication, setPublication] = useState(EMPTY_PUBLICATION);

  const extraAvitoState = vacancy.extraAvito?.state;

  const initialState = useMemo<AvitoState>(
    () => getInitialState(vacancy),
    [vacancy]
  );

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<AvitoState>({
    // @ts-expect-error
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const managerId = useWatch({
    control,
    name: "managerId",
  });

  const { phone, email } = useMemo(
    () => getContacts(managerId, managerList),
    [managerId, managerList]
  );

  const publicationMessage = useMemo(() => {
    const { state } = vacancy.extraAvito || {};

    return state ? publicationMessageByState[state] : null;
  }, [vacancy.extraAvito]);

  const onPublish: SubmitHandler<AvitoState> = useCallback(
    async (formData) => {
      setLoading(true);
      const payload = getPayload(formData, { managerList, age: vacancy.age });
      const isUpdate = extraAvitoState
        ? isPublicationStatus(extraAvitoState)
        : false;
      const vacancyOnAvitoAction = isUpdate
        ? updateVacancyOnAvito
        : publishVacancyOnAvito;

      try {
        await dispatch(
          vacancyOnAvitoAction({
            id: vacancy.id,
            userId: managerId,
            vacancy: payload,
          })
        ).unwrap();

        setPublication({
          status: "success",
          message: `Вакансия успешно ${
            isUpdate ? "обновлена" : "опубликована"
          }`,
        });
      } catch (e) {
        setPublication({
          status: "error",
          message: e || `Ошибка ${isUpdate ? "обновления" : "публикации"}`,
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatch, managerId, managerList, vacancy, extraAvitoState]
  );
  const handleClosePublishModal = () => {
    setPublication((prev) => ({ ...prev, message: null }));

    if (publication.status === "success") {
      history.goBack();
    }
  };

  useEffect(() => {
    reset(initialState);
  }, [initialState, reset]);

  return (
    <div className="avito-form">
      <Title type="h2">Опубликовать вакансию</Title>

      {publicationMessage && (
        <Notification
          type={"warning"}
          title={publicationMessage}
          className="avito-form-notification"
        />
      )}

      {vacancy.status !== VacancyStatus.Published && (
        <Notification
          type="error"
          title='Для публикации на Авито, вакансия должна быть в статусе "Опубликовано"'
          className="avito-form-notification"
        />
      )}

      <Title type="h3" color="blue">
        Описание
      </Title>

      <Controller
        name="title"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormInput
            error={error?.message}
            label="Название обьявления"
            required={true}
            disabled={true}
            {...field}
          />
        )}
      />

      <Controller
        name="profession"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Профессия"
            showSearch
            placeholder="Укажите профессию"
            optionFilterProp="children"
            filterOption={filterOption}
            options={getSelectOptions(professionsOptions)}
            {...field}
          />
        )}
      />

      <Controller
        name="shortDescription"
        control={control}
        render={({ field }) => (
          <Editor
            containerClassName="avito-form-desc"
            disabled={true}
            label="Описание вакансии"
            count={VACANCY_CHARACTER_LIMITS.SHORT_DESCRIPTION}
            {...field}
          />
        )}
      />

      <Controller
        name="description"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <Editor
            containerClassName="avito-form-desc"
            disabled={true}
            label="Обязанности"
            required={true}
            error={error?.message}
            count={VACANCY_CHARACTER_LIMITS.DESCRIPTION}
            {...field}
          />
        )}
      />

      <Controller
        name="terms"
        control={control}
        render={({ field }) => (
          <Editor
            containerClassName="avito-form-desc"
            disabled={true}
            label="Условия"
            count={VACANCY_CHARACTER_LIMITS.TERMS}
            {...field}
          />
        )}
      />

      <Controller
        name="claims"
        control={control}
        render={({ field }) => (
          <Editor
            containerClassName="avito-form-desc"
            disabled={true}
            label="Требования"
            count={VACANCY_CHARACTER_LIMITS.CLAIMS}
            {...field}
          />
        )}
      />

      <Controller
        name="platformTags"
        control={control}
        render={({ field }) => (
          <Editor
            label="Ключевые слова"
            disabled={true}
            containerClassName="avito-form-desc"
            count={VACANCY_CHARACTER_LIMITS.PLATFORM_TAGS}
            {...field}
          />
        )}
      />

      <Title type="h3" color="blue">
        Условия для кандидата
      </Title>

      <Controller
        name="employment"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormSelect
            label="Тип занятости"
            required={true}
            error={error?.message}
            options={getSelectOptions(employmentAvitoOptions)}
            {...field}
          />
        )}
      />

      <Controller
        name="experience"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormSelect
            label="Опыт работы"
            required={true}
            error={error?.message}
            options={getSelectOptions(experienceAvitoOptions)}
            {...field}
          />
        )}
      />

      <Controller
        name="schedule"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormSelect
            label="График работы"
            placeholder="Укажите график работы"
            required={true}
            error={error?.message}
            options={getSelectOptions(scheduleAvitoOptions)}
            {...field}
          />
        )}
      />

      <FormInput
        label="Работодатель"
        disabled={true}
        value={vacancy.employer?.name ?? ""}
      />

      <Controller
        name="registrationMethod"
        control={control}
        render={({ field }) => (
          <FormCheckbox
            label="Способ оформления"
            className="avito-form-checkbox"
            options={registrationMethodAvitoOptions.map(({ key, name }) => ({
              label: name,
              value: key,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="workFormat"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Формат работы"
            mode="multiple"
            options={getSelectOptions(workFormatAvitoOptions)}
            {...field}
          />
        )}
      />

      <RowLayout
        style={{ display: "inlineBlock", alignItems: "center" }}
        leftComponent={
          <Controller
            name="salaryFrom"
            control={control}
            render={({ field }) => (
              <FormInput
                label="Предполагаемый уровень дохода в месяц"
                disabled={true}
                {...field}
              />
            )}
          />
        }
        leftSpan={12}
        rightComponent={
          <Controller
            name="salaryTo"
            control={control}
            render={({ field }) => <Input disabled={true} {...field} />}
          />
        }
        rightSpan={12}
      />

      <Controller
        name="bonuses"
        control={control}
        render={({ field }) => (
          <FormCheckbox
            label="Что получают работники"
            options={bonusesAvitoOptions.map(({ key, name }) => ({
              label: name,
              value: key,
            }))}
            {...field}
          />
        )}
      />

      <Title type="h3" color="blue">
        Место работы
      </Title>

      <Controller
        name="address"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormSelectDadata
            label="Где будет работать сотрудник"
            error={error?.message}
            required={true}
            disabled={true}
            {...field}
          />
        )}
      />

      <Title type="h3" color="blue">
        Контакты
      </Title>

      <FormText label="Телефон" text={maskPhone(phone)} />
      <FormText label="Адрес электронной почты" text={email} />

      <Controller
        name="managerId"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormSelect
            label="Менеджер вакансии"
            showSearch
            required={true}
            optionFilterProp="children"
            filterOption={filterOption}
            error={error?.message}
            options={getSelectOptions(managerList)}
            {...field}
          />
        )}
      />

      <Button
        loading={loading}
        disabled={
          Boolean(Object.values(errors).length) ||
          (extraAvitoState ? isInProgressStatus(extraAvitoState) : false)
        }
        onClick={handleSubmit(onPublish)}
      >
        {getButtontext(vacancy.extraAvito?.state)}
      </Button>

      <PublicationStatusModal
        isVisible={Boolean(publication.message)}
        onClose={handleClosePublishModal}
        publication={publication}
        jobBoard="avito"
      />
    </div>
  );
};
