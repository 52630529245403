import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ContentLayout } from "layouts/ContentLayout";
import { CandidateReply } from "components/redezign/CandidateReply";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { fetchVacanciesDictionaries } from "services/store/modules/vacanciesList/actions";
import { fetchCandidateDictionaries } from "services/store/modules/candidate/actions";
import { getReplyNew } from "services/store/modules/repliesNew/actions";
import { RouteParams } from "common/commonTypes/types";
import { selectReplyNew } from "services/store/modules/repliesNew/selectors";
import { authInfo } from "services/store/modules/auth";

export const CandidateReplyPage: React.FC = () => {
  const { id: replyId } = useParams<RouteParams>();
  const dispatch = useAppDispatch();

  const reply = useAppSelector(selectReplyNew);
  const authData = useAppSelector(authInfo);

  useEffect(() => {
    Promise.all([
      dispatch(fetchVacanciesDictionaries()),
      dispatch(fetchCandidateDictionaries()),
      dispatch(getReplyNew({ id: replyId })),
    ]);
  }, []);

  return (
    <ContentLayout
      centralColumn={{
        content:
          reply && authData ? (
            <CandidateReply authData={authData} reply={reply} />
          ) : null,
      }}
    />
  );
};
