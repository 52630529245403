import React, { useState } from "react";
import { Moment } from "moment";

import { FormInput } from "components/UI/Form/FormInput";
import { FormSelect } from "components/UI/Form/FormSelect";
import FormDatePicker from "components/UI/Form/FormDatePicker";
import { CandidateStatus, CandidateStatusesType } from "graphql/types/types";
import { filterOption, getSelectOptions } from "components/UI/Select/helpers";
import { FormTreeSelect } from "components/UI/Form/FormTreeSelect";
import { IDirectoryTreeItem } from "services/store/modules/directory/types";
import { getAreaTreeOptions } from "components/UI/TreeSelect/helpers";

type Props = {
  className?: string;
  candidateStatuses: CandidateStatusesType[];
  areasOptions: IDirectoryTreeItem[];
};

type FiltersState = {
  vacancyId: string;
  date: Moment | null;
  region: string | null;
  status: CandidateStatus[];
};

const initialState: FiltersState = {
  vacancyId: "",
  date: null,
  region: null,
  status: [],
};

type Name = keyof FiltersState;
type Value = FiltersState[keyof FiltersState];

export const Filters: React.FC<Props> = ({
  candidateStatuses,
  areasOptions,
}) => {
  const [state, setState] = useState(initialState);

  const updateState = (name: Name, value: Value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <FormInput
        placeholder="Введите ID"
        label="ID вакансии"
        style={{ width: "160px" }}
        value={state.vacancyId}
        onChange={(e) => updateState("vacancyId", e.target.value)}
      />
      <FormDatePicker
        placeholder="Выберите дату"
        format="DD MM YYYY"
        label="Дата перевода"
        style={{ width: "160px" }}
        value={state.date}
        onChange={(value) => updateState("date", value)}
      />

      <FormTreeSelect
        label="Регион вакансии"
        placeholder="Выберите регион"
        style={{ width: "320px" }}
        treeData={getAreaTreeOptions(areasOptions, true)}
        value={state.region}
        allowClear={true}
        treeDefaultExpandAll={true}
        showSearch={true}
        onChange={(value) => updateState("region", value)}
      />

      <FormSelect
        style={{ width: "320px" }}
        placeholder="Выберите статус"
        label="Статус кандидата"
        showSearch
        mode="multiple"
        optionFilterProp="children"
        filterOption={filterOption}
        options={getSelectOptions(candidateStatuses)}
        value={state.status}
        onChange={(value) => updateState("status", value)}
      />
    </>
  );
};
