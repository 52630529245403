import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getInitialState } from "../../helpers";
import {
  CandidateType,
  ReplyNewClientType,
  ReplyNewStatuses,
} from "graphql/types/types";
import { getReplyNew, getReplyNewList } from "./actions";

const repliesNewListSlice = createSlice({
  name: "repliesNewList",
  initialState: getInitialState<ReplyNewClientType[]>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReplyNewList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReplyNewList.fulfilled, (state, { payload }) => {
        state.data = payload?.replyNewList.data ?? [];
        state.meta = payload?.replyNewList.meta ?? null;
        state.loading = false;
      })
      .addCase(getReplyNewList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});

const replyNewSlice = createSlice({
  name: "replyNew",
  initialState: getInitialState<ReplyNewClientType>(),
  reducers: {
    setInProgressBy(
      state,
      {
        payload,
      }: PayloadAction<{ inProgressBy: number; status: ReplyNewStatuses }>
    ) {
      if (!state.data) return;
      state.data.inProgressBy = payload.inProgressBy;
      state.data.status = payload.status;
    },
    setChangeStatus(
      state,
      { payload }: PayloadAction<{ status: ReplyNewStatuses }>
    ) {
      if (!state.data) return;
      state.data.status = payload.status;
    },
    updateCandidateInReplyNew(
      state,
      { payload }: PayloadAction<CandidateType>
    ) {
      if (!state.data) return;
      state.data.candidate = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReplyNew.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReplyNew.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.replyNew;
      })
      .addCase(getReplyNew.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});
export const { setInProgressBy, setChangeStatus, updateCandidateInReplyNew } =
  replyNewSlice.actions;
export const repliesNewReducer = combineReducers({
  repliesNewList: repliesNewListSlice.reducer,
  replyNew: replyNewSlice.reducer,
});
